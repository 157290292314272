.hamburger {
    height: 24px;
    // width: 28px;
    aspect-ratio: 1.2;
    position: relative;
    cursor: pointer;
    div {
        background-color: var(--false-black);
        position: absolute;
        transition-property: all;
        transition-duration: .4s;
        border-radius: 3px;
        cursor: pointer;
    }
    &.open {
        div {
            height: 20%;
            &:nth-child(1) {
                width: 130%;
                top: 40%;
                left:-15%;
                transform: rotate(40deg);
            }
            &:nth-child(2) {
                width: 0;
                top: 40%;
                left: 50%;
            }
            &:nth-child(3) {
                width: 130%;
                bottom: 40%;
                left: -15%;
                transform: rotate(-40deg);
            }
        }
    }
    &.closed {
        div {
            width: 100%;
            height: 20%;
            // border-radius: 3px;
            left: 0;
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2) {
                top: 40%;
            }
            &:nth-child(3) {
                bottom: 0;
            }
        }
    }
}
