.error-box {
    border-radius: 6px;
    width: 400px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        color: white;
        font-size: 16px !important;
        text-align: center !important;
    }
}