#popup-root{
    position: fixed;
    height: 0;
    width: 0;
    bottom: -1px;
    z-index: 10;
}
.block-scroll {
    overflow: hidden;
}
.popup-overlay {
    position: fixed;
    top: env(safe-area-inset-top);
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup-content {
        position: relative;

        .close {
            position: absolute;
            top: 16px;
            right: 16px;
            font-size: 32px;
            font-weight: 400;
            text-decoration: none;
            color: var(--false-black);
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .close:hover {
            font-weight: 800;
        }
    }
}
