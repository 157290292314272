.popup-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    .user {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px;
        .user-header {
            .title-button {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 12px;
                button {
                    width: 40px;
                    height: 40px;
                }
                button:active {
                    background-color: white;
                    border: 1px solid var(--green-enjoy);
                }
            }
        }
        #email-inputs {
            margin-top: 12px;
            input {
                margin-top: 8px;
                border-radius: 6px;
                outline: none;
                border: 1px solid lightgray;
                padding: 16px;
            }
        }
    }
    .popup {
        background-color: white;
        max-width: 800px;
        width: 80vw;
        height: 50%;
        min-height: 500px;
        border-radius: 30px;
        overflow: hidden;
        position: absolute;
        display: flex;
        .croppy-croppy-cropper {
            background-color: lightgray;
        }
        .popup-elements {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            width: 50%;
            padding: 32px 48px;
            box-sizing: border-box;
            > span {
                font-size: 0.8rem;
                // cursor: default;
                align-self: flex-end;
                &:first-of-type {
                    cursor: pointer;
                    align-self: flex-end;
                    &:hover {
                        color: var(--green-enjoy);
                    }
                }
            }
        }
        h1 {
            margin: 16px 0 10px;
            align-self: flex-start;
            font-size: 30px;
        }
        p {
            padding-bottom: 8px;
            margin: 0 0 8px;
            color: #8692a6;
            border-bottom: 1px solid #f5f5f5;
            font-size: 18px;
            line-height: 28px;
        }
        div {
            display: flex;
            flex-direction: column;
            width: 100%;
            .password-input {
                margin-block: 10px;
                border-radius: 6px;
                border: 1px solid lightgray;
                flex-direction: row;
                input {
                    padding: 20px 24px;
                    border: none;
                    outline: none;
                }
            }
            div:not(.password-input) > input {
                margin-block: 10px;
                padding: 20px 24px;
                border-radius: 6px;
                border: 1px solid lightgray;
            }
        }

        a {
            color: var(--green-enjoy);
        }
        a:hover {
            cursor: pointer;
        }
        // > span {
        //     font-size: 0.8rem;
        //     cursor: default;
        //     align-self: flex-end;
        //     &:first-of-type {
        //         cursor: pointer;
        //         align-self: flex-end;
        //         &:hover {
        //             color: var(--green-enjoy);
        //         }
        //     }
        // }
        button {
            width: 100%;
            margin: 10px 0;
            height: 50px;
            background-color: var(--green-enjoy);
            color: white;
            border-radius: 4px;
            border: none;
            font-family: Helvetica;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
        }
        button:active {
            color: var(--green-enjoy);
            background-color: white;
            border: 1px solid var(--green-enjoy);
        }
        .right-elements {
            width: 50%;
            height: 100%;
            flex-shrink: 0;
            background: linear-gradient(0deg, rgba(183, 205, 0, 0.9), rgba(183, 205, 0, 0.9)),
                url("https://timenjoy.fr/static/media/nice.774a3eed.jpg");
            background-position-x: 0%, 0%;
            background-position-y: 0%, 0%;
            background-repeat: repeat, repeat;
            background-size: auto, auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;
            padding: 32px 48px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            * {
                color: white;
                border: none;
            }
            p {
                font-weight: 500;
            }
            button {
                color: var(--green-enjoy);
                background-color: white;
            }
        }
    }
    .update {
        padding: 40px;
        height: auto;
        flex-direction: column;
        width: 600px;
        box-sizing: border-box;
        min-height: unset;
        border-radius: 6px;
        color: var(--black-enjoy);
        font-family: var(--roboto);
        > div:first-child {
            margin-bottom: 40px;
            font-size: 20px;
        }
        > div:nth-child(2) {
            flex-direction: row;
            margin-bottom: 16px;
        }
        > div:nth-child(4) {
            > button {
                margin: 0;
                height: 44px;
                &:first-child:not(:hover) {
                    background-color: white;
                    border: 2px solid var(--green-enjoy);
                    color: var(--green-enjoy);
                }
                &:last-child {
                    margin-left: 16px;
                }
            }
        }
        .update-grid {
            border-top: 1px solid lightgray;
            padding-top: 16px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-bottom: 32px;
            gap: 12px;
        }
        .item {
            flex-direction: row;
            .tnj_ui_checkbox {
                margin-right: 16px;
                height: 20px;
                width: 20px;
            }
            span {
                align-self: center;
            }
        }
    }
}
