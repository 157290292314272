.register-part {
    width: 100vw;
    height: 100vh;
    background-color: white;
    overflow: hidden;
    display: flex;
    align-items: center;
    overflow: auto;
    justify-content: center;
    h4 {
        font-family: var(--inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #696f79;
    }
    > img {
        position: absolute;
        width: 150px;
        height: 40.63px;
        left: 4vw;
        top: 5vh;
        cursor: pointer;
    }

    .register-card {
        width: 60%;
        // margin: 0 auto;
        // padding: 170px;
        // margin-top: 5vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 40px;

        h1 {
            font-family: var(--inter);
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: left;
            &:not(:is(.title-input h1)) {
                margin-bottom: 10px;
            }
        }
        h2 {
            font-family: var(--inter);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #8692a6;
            // margin-bottom: 6vh;
        }
        .separation {
            border-bottom: 1px solid #f5f5f5;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        .register-form {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: min(24px, 2vh) 70px;
            margin-bottom: 24px;
            h4 {
                font-family: var(--inter);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: #696f79;
                margin-bottom: 14px;
            }

            input {
                border: 1px solid lightgray;
                border-radius: 6px;
                // padding: 2px;
                height: 48px;
                padding: 0 30px;
                display: flex;
                align-items: center;

                font-family: var(--inter);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #8692a6;

                width: 100%;
                box-sizing: border-box;
            }
            input:focus {
                outline: none !important;
                border: 1px solid var(--green-enjoy);
            }
            .checkbox {
                display: flex;
                align-items: center;
                padding: 13px 0 15;
                h4 {
                    // height: auto;
                    margin: 0 0 0 13px;
                }
            }
        }

        .line-buttons {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 70px;

            .timenjoy {
                border-radius: 6px;
                background-color: var(--green-enjoy);
                color: white;
                border: none;
                // width: 49%;
                height: 60px;

                font-family: var(--inter);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: center;
            }

            .google {
                // width: 49%;
                height: 60px;
                border-radius: 6px;
                background-color: white;
                color: black;
                border: none;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);

                font-family: var(--inter);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: center;
            }

            .switch-button {
                // position: absolute;
                // width: 194.09px;
                height: 60px;
                width: 200px;
                justify-self: flex-end;
                // right: 20%;
                // top: 70%;
                border: none;
                background: var(--green-enjoy);
                color: white;
                border-radius: 5px;
                font-family: var(--roboto);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
            }

            .disabled {
                background-color: lightgray;
            }
        }

        .select-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // flex-grow: 1;
            margin-top: 6vh;
            .title-input {
                display: flex;
                // align-items: baseline;
                align-items: flex-end;
                margin-bottom: 5vh;
                // justify-content: space-between;
                h1 {
                    // margin-top: 7%;
                    font-family: var(--inter);
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: 0em;
                    text-align: left;
                    // min-width: 200px;
                    &:nth-of-type(2) {
                        font-weight: 400;
                        margin-left: 10px;
                        padding: 8px 12px;
                        font-size: 18px;
                        border-bottom: 2px solid var(--green-enjoy);
                        min-width: 180px;
                    }
                }
                // span {
                //     margin-left: 20px;
                //     display: flex;
                //     align-items: flex-end;
                //     border: none;
                //     border-bottom: 2px solid var(--green-enjoy);
                //     min-width: 219.117px;
                //     width: auto;
                //     outline: none;
                // }
            }

            .choice-card {
                // margin-top: 45px;
                margin-bottom: 30px;
                padding: 32px;
                box-shadow: 0px 3px 11px rgba(193, 193, 193, 0.25);
                border-radius: 10px;
                &,
                * {
                    cursor: pointer;
                }
                // width: 69%;
                h3,
                h4 {
                    font-weight: 400;
                    font-family: var(--roboto);
                }
                h3 {
                    font-size: 20px;
                    line-height: 23px;
                    color: #0b0b0b;
                }
                h4 {
                    margin-top: 5px;
                    font-size: 18px;
                    line-height: 21px;
                    color: #a2abbe;
                }
                &:last-of-type {
                    margin-bottom: 8vh;
                }
                &.inactive {
                    background-color: lightgray;
                    opacity: 0.3;
                    cursor: default;
                    box-shadow: none;
                    h3,
                    h4 {
                        cursor: default;
                        color: black !important;
                    }
                }
            }

            .card-background {
                background-color: var(--green-enjoy);

                h3,
                h4 {
                    color: white;
                }
            }
        }
    }
    .errors-boxes {
        position: absolute;
        bottom: 5%;
        right: 5%;
    }
}

@media (max-height: 800px) {
    .register-part{
        align-items: flex-start;
    }
}