.password-input {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
    input {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        box-sizing: border-box;
    }
    img {
        position: absolute;
        right: 8px;
        padding: 8px;
        border-radius: 6px;
        &:active {
            background-color: lightgrey;
        }
    }
}
