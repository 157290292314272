.rounded-check-box {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    padding: 4px;
    box-sizing: border-box;
    background-color: #ebebeb;
    cursor: pointer;
    flex-shrink: 0;
    > div {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background: none;
        &.on {
            background-color: var(--green-enjoy);
        }
    }
}
