.tnj-switch {
    display: flex;
    justify-content: flex-start;
    background-color: rgb(177, 177, 177);
    height: 20px;
    width: 38px;
    overflow: hidden;
    padding: 1px;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    div {
        border-radius: 50%;
        height: 18px;
        width: 18px;
        transition-duration: 1s;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.125);
    }
    &.on {
        justify-content: flex-end;
        background-color: var(--green-enjoy);
    }
}
