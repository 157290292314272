main.perf {
    section {
        &.hero {
            padding: 100px var(--margin) 100px var(--margin);
            align-items: center;
            min-height: 75vh;
            .text {
                h1 {
                    margin-bottom: 32px;
                }
                h5 {
                    max-width: unset;
                    margin-bottom: 40px;
                }
            }
            img {
                max-width: 35vw;
                object-fit: unset;
                object-position: unset;
                border-radius: 8px;
            }
        }
        &.sect-3 {
            margin-top: 200px;
            margin-bottom: 100px;
        }
        &.sect-5 {
            .zarbi {
                margin-bottom: 120px;
            }
        }
        &.sect-6 {
            display: flex;
            flex-direction: column;
            align-items: center;
            h2 {
                width: 100%;
            }
            .row {
                margin-bottom: 40px;
                > div > div p {
                    width: unset;
                    text-align: center;
                    margin: 0 16px 8px;
                }
            }
        }
        &.sect-13 {
            h2{
                text-align: center;
                width: 100%;
            }
        }
    }
}
@media screen and (max-height: 900px) {
    main.perf .hero {
        padding-top: 40px;
        .text h1 {
            font-size: 44px;
        }
    }
}

@media screen and (max-width: 1440px) {
    main.perf {
        section.hero {
            .text {
                min-width: 500px;
                margin-right: 40px;
            }
            > img {
                // min-width: 400px;
                min-height: 300px;
                object-fit: cover;
                object-position: right;
                max-width: unset;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    main.perf {
        section.hero {
            flex-direction: column;
            .text {
                margin-right: 0;
                margin-bottom: 40px;
                min-width: unset;
            }
            > img {
                width: 100%;
                // width: calc(100vw - var(--margin));
                max-height: 350px;
                margin: 0;
            }
        }
    }
}
@media screen and (max-width: 540px) {
    main.perf {
        section.hero {
            padding-top: 40px;
            // min-height: 60vh;
            justify-content: center;
            .text h1 {
                min-width: unset;
                font-size: 44px;
            }
            > img {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 380px) {
    main.perf {
        section.hero {
            padding-top: 16px; 
            padding-bottom: 0; 
            .text {
                h1 {
                    font-size: 32px;
                    margin-bottom: 24px;
                }
                h3 {
                    font-size: 20px;
                    display: none;
                }
                h5 {
                    font-size: 20px;
                }
            }
        }
    }
}